
.pickerDropPaneContainer {
  display: flex;
  justify-content: center;
}

.pickerDropPaneContainer .fsp-drop-pane__container {
  width: 85.60mm !important;
  height: 53.98mm !important;
  border-radius: 0px !important;
}


